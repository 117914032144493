import React from 'react';
import { Helmet } from 'react-helmet';

import { WEBSITE_URL } from '~/constants';
import Layout from '~/components/Layout';
import mastaz from '~/data/mastaz';
import routes from '~/routes';

import Entry from './components/Entry';
const InternshipComponent: React.FC = () => {
  const { meta, entrySection } = mastaz;

  return (
    <Layout pageTitle={meta.title}>
      <Helmet>
        <meta key='twitter:title' name='twitter:title' content={meta.title} />
        <meta key='og:title' property='og:title' content={meta.title} />
        <meta key='description' name='description' content={meta.description} />
        <meta
          key='og:description'
          property='og:description'
          content={meta.description}
        />
        <meta
          key='twitter:description'
          name='twitter:description'
          content={meta.description}
        />
        <meta
          key='image'
          itemProp='image'
          content={`${WEBSITE_URL}${meta.imageUrl}`}
        />
        <meta
          key='twitter:image:src'
          name='twitter:image:src'
          content={`${WEBSITE_URL}${meta.imageUrl}`}
        />
        <meta
          key='og:image'
          property='og:image'
          content={`${WEBSITE_URL}${meta.imageUrl}`}
        />
        <meta
          key='og:url'
          property='og:url'
          content={`${WEBSITE_URL}${routes.INTERNSHIP}`}
        />
        <meta
          key='url'
          itemProp='url'
          content={`${WEBSITE_URL}${routes.INTERNSHIP}`}
        />
        <link rel='canonical' href={`${WEBSITE_URL}${routes.INTERNSHIP}`} />
      </Helmet>
      <Entry data={entrySection} />
    </Layout>
  );
};

export default InternshipComponent;
