import { KeyFactsSectionDTO } from '~/pages/mastaz/types';
import forWhomUrl from './assets/for-whom.svg';
import whatUrl from './assets/what.svg';
import whenUrl from './assets/when.svg';
import whereUrl from './assets/where.svg';

const valuesSection: KeyFactsSectionDTO = {
  title: 'Learn More About MaStaż',
  items: [
    {
      title: 'What',
      icon: { src: whatUrl, alt: 'laptop' },
      description: 'Mobile (iOS)',
    },
    {
      title: 'Where',
      icon: { src: whereUrl, alt: 'warsaw' },
      description: 'Nowy Świat, Warsaw',
    },
    {
      title: 'When',
      icon: { src: whenUrl, alt: 'calendar' },
      description: 'Jul 18 – Aug 26, 2022 (6 weeks)',
    },
    {
      title: 'For Whom',
      icon: { src: forWhomUrl, alt: 'laptop' },
      description: 'Students, aspiring devs',
    },
  ],
};

export default valuesSection;
