import { SignUpSectionDTO } from '~/pages/mastaz/types';

import video from './assets/apply.mp4';

const videoPreview =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYPhfDwAChwGA60e6kgAAAABJRU5ErkJggg==';

const signUpSection: SignUpSectionDTO = {
  video,
  videoPreview,
};

export default signUpSection;
