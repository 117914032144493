import { EntrySectionDTO } from '~/pages/mastaz/types';

import backgroundVideo from './assets/background-video.mp4';

const backgroundVideoPreview =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYPhfDwAChwGA60e6kgAAAABJRU5ErkJggg==';

const entrySection: EntrySectionDTO = {
  backgroundVideo,
  backgroundVideoPreview,
  videoUrl: 'https://www.youtube-nocookie.com/embed/pvb4C-qphrk',
};

export default entrySection;
