import { MetaDTO } from '~/pages/mastaz/types';

import metaImage from './assets/internship-placeholder.jpg';

const meta: MetaDTO = {
  title: 'SwingDev MaStaż: Summer Development Internship in Warsaw',
  description:
    "Are you into programming? Learn what it's like to be a part of a fast-growing InsurTech company in Silicon Valley. Paid Mobile (iOS) internship at the heart of Warsaw. Sign up today!",
  imageUrl: metaImage,
};

export default meta;
