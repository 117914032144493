import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

import Figure3Url from '~/assets/shapes-internship/internship-3.svg';
import Figure4Url from '~/assets/shapes-internship/internship-4.svg';
import Button from '~/components/Button';
import { Video } from '~/components/Video';
import useVideos from '~/hooks/useVideos';

import { EntrySectionDTO } from '../../types';

import mainImageSmUrl from './assets/main-sm.jpg';
import swingInternUrl from './assets/swing-intern-white.svg';
import styles from './MainHeader.module.scss';
import { VideoPreview } from '~/components/VideoPreview';
import routes from '~/routes';

type Props = {
  data: EntrySectionDTO;
};

const Entry: React.FC<Props> = ({ data }) => {
  const { getVideo, getVideoPreview } = useVideos();
  const backgroundVideo = getVideo(data.backgroundVideo);
  const backgroundVideoPreview = getVideoPreview(data.backgroundVideoPreview);

  const [showVideoPreview, setShotVideoPreview] = useState(false);
  const toggleVideoPreview = useCallback(() => {
    setShotVideoPreview((s) => !s);
  }, []);

  return (
    <section className={styles.main}>
      <img
        className={styles.figure1}
        src={Figure4Url}
        alt=''
        role='presentation'
      />
      <img
        className={styles.figure2}
        src={Figure4Url}
        alt=''
        role='presentation'
      />
      <img
        className={styles.figure3}
        src={Figure3Url}
        alt=''
        role='presentation'
      />

      <div className={styles.videoWrapper}>
        {backgroundVideo ? (
          <Video
            video={backgroundVideo}
            videoPreview={backgroundVideoPreview}
          />
        ) : null}
      </div>

      <div className={styles.imageSmWrapper}>
        <img
          src={mainImageSmUrl}
          className={styles.imageSm}
          alt='our programmers'
        />
      </div>

      <div className={classNames(styles.container, styles.mediumContainer)}>
        <img src={swingInternUrl} alt='SwingMaStaż' className={styles.logo} />

        <h1 className={classNames(styles.title, styles.mediumContainer)}>
          MaStaż 2023
        </h1>
        <div className={classNames(styles.subtitle, styles.mediumContainer)}>
          Interested in attending our internship program? Click &quot;notify
          me&quot; and leave us your email address if you want to be informed
          about the next edition!
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            isSpecialWhite
            className={styles.button}
            onClick={toggleVideoPreview}
          >
            WATCH 2022 EDITION
          </Button>
          <Button
            as={{
              tag: 'a',
              href: routes.INTERNSHIP_RECRUITMENT,
            }}
            className={styles.button}
          >
            NOTIFY ME
          </Button>
        </div>
      </div>

      <VideoPreview
        videoUrl={data.videoUrl}
        title='MaStaż 2022 Edition'
        show={showVideoPreview}
        onClose={toggleVideoPreview}
      />
    </section>
  );
};

export default Entry;
