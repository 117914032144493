import { GallerySectionDTO } from '~/pages/mastaz/types';

import NewDesktop1Image from './assets/n1.jpg';
import NewDesktop2Image from './assets/n2.jpg';
import NewDesktop3Image from './assets/n3.jpg';

import NewMobile1Image from './assets/n1-mobile.jpg';
import NewMobile2Image from './assets/n2-mobile.jpg';
import NewMobile3Image from './assets/n3-mobile.jpg';

import Desktop1Image from './assets/1.jpg';
import Desktop2Image from './assets/2.jpg';
import Desktop3Image from './assets/3.jpg';
import Desktop4Image from './assets/4.jpg';
import Desktop5Image from './assets/5.jpg';
import Desktop8Image from './assets/8.jpg';
import Desktop9Image from './assets/9.jpg';
import Desktop10Image from './assets/10.jpg';

import Mobile1Image from './assets/1-mobile.jpg';
import Mobile2Image from './assets/2-mobile.jpg';
import Mobile3Image from './assets/3-mobile.jpg';
import Mobile4Image from './assets/4-mobile.jpg';
import Mobile8Image from './assets/8-mobile.jpg';
import Mobile9Image from './assets/9-mobile.jpg';
import Mobile10Image from './assets/10-mobile.jpg';
const gallerySection: GallerySectionDTO = {
  images: [
    {
      img: NewDesktop2Image,
      imgMobile: NewMobile2Image,
    },
    {
      img: NewDesktop3Image,
      imgMobile: NewMobile3Image,
    },
    {
      img: NewDesktop1Image,
      imgMobile: NewMobile1Image,
    },
    {
      img: Desktop1Image,
      imgMobile: Mobile1Image,
    },
    {
      img: Desktop2Image,
      imgMobile: Mobile2Image,
    },
    {
      img: Desktop3Image,
      imgMobile: Mobile3Image,
    },
    {
      img: Desktop4Image,
      imgMobile: Mobile4Image,
    },
    {
      img: Desktop5Image,
    },
    {
      img: Desktop8Image,
      imgMobile: Mobile8Image,
    },
    {
      img: Desktop9Image,
      imgMobile: Mobile9Image,
    },
    {
      img: Desktop10Image,
      imgMobile: Mobile10Image,
    },
  ],
};

export default gallerySection;
