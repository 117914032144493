import React from 'react';

import { QAndASection } from '~/pages/mastaz/types';

const qAndASection: QAndASection = {
  questions: [
    {
      question:
        'Will the interns be hired at SwingDev after  completing the internship?',
      answer:
        'It will depend entirely on the performance of the interns. Each case will be reviewed separately. For example, last edition 2 out of the 4 people ended up being hired at SwingDev as Junior Front-End Developers.',
    },
    {
      question:
        'Will the internship take place in the office? Can I work remotely?',
      answer: (
        <span>
          The internship will be run in our office in Warsaw. We will consider
          only candidates who can come to the office for the 6 weeks of the
          internship. Partially remote work will be possible, but it will need
          to be scheduled with the rest of the team :)
          <br />
          <br />
          Our office is a fantastic loft-style space in downtown Warsaw (Nowy
          Świat) with numerous commuting options and a space for your bike.
        </span>
      ),
    },
    {
      question:
        'Will interns get the same benefits package as the regular employees at SwingDev?',
      answer: (
        <span>
          No, we can&apos;t provide you with the same benefits package for the 6
          weeks of the internship. Despite that, we want to give you a fair
          remuneration and as much of the SwingDev vibes as we can.
          <br />
          <br />
          We will pay you <b>5500 PLN gross</b> based on a contract of mandate
          (umowa zlecenie). Of course, you will be able to enjoy all the nice
          things in our office. Apart from the basics, we have snacks and fresh
          ice cream, and a chill room with table football & Playstation 4.
        </span>
      ),
    },
    {
      question: 'When will I get the recruitment task?',
      answer:
        'We will let you know about the details once we open the application process.',
    },
  ],
};

export default qAndASection;
