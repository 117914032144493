import { MastazDTO } from '~/pages/mastaz/types';

import meta from './meta';
import entrySection from './entrySection';
import signUpSection from './signUpSection';
import qAndASection from './qAndASection';
import gallerySection from './gallerySection';
import keyFactsSection from './keyFactsSection';

const mastaz: MastazDTO = {
  meta,
  gallerySection,
  entrySection,
  signUpSection,
  qAndASection,
  keyFactsSection,
};

export default mastaz;
